import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";

import { fetchUserDetails } from "src/api/user.ts";
import { UserForm } from "src/components/userForm.tsx";
import { User } from "src/model/user.ts";

export const UserDetailsPage: FC = () => {
  const { userId } = useParams();
  const [user, setUser] = useState<User | null | undefined>();

  useEffect(() => {
    if (userId !== undefined) {
      if (userId === "new") {
        setUser(null);
      } else {
        fetchUserDetails(parseInt(userId)).then((user) => setUser(user));
      }
    }
  }, [userId]);

  if (user === undefined) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>iSEA Trace - {user === null ? "New User" : user.userName}</title>
      </Helmet>
      <UserForm user={user} />
    </>
  );
};
