import { ContainerFilterProps } from "src/model/container.ts";
import { SubscriptionStatus } from "src/model/subscriptionStatus.ts";

interface ContainerFilterQuery {
  custId?: string;
  compId?: string;
  query?: string;
  portOfLoadings?: string[];
  portOfDischarges?: string[];
  scacs?: string[];
  etaFrom?: number;
  etaTo?: number;
  status?: SubscriptionStatus;
}

const numberToDate = (n?: number): Date | undefined => {
  if (n === undefined) {
    return undefined;
  }

  return new Date(n);
};

const mapFilterToQuery = (filter: ContainerFilterProps): ContainerFilterQuery => {
  return {
    custId: filter.customerId,
    compId: filter.companyId,
    query: filter.query,
    portOfLoadings: filter.portOfLoadingCodes,
    portOfDischarges: filter.portOfDischargeCodes,
    scacs: filter.scacs,
    etaFrom: filter.etaPortOfDischarge?.at(0)?.getTime(),
    etaTo: filter.etaPortOfDischarge?.at(1)?.getTime(),
    status: filter.status,
  };
};

const mapQueryToFilter = (query: ContainerFilterQuery): ContainerFilterProps => {
  return {
    customerId: query.custId,
    companyId: query.compId,
    query: query.query,
    portOfLoadingCodes: query.portOfLoadings,
    portOfDischargeCodes: query.portOfDischarges,
    scacs: query.scacs,
    etaPortOfDischarge:
      query.etaFrom || query.etaTo ? [numberToDate(query.etaFrom), numberToDate(query.etaTo)] : undefined,
    status: query.status,
  };
};

export const buildContainerFilterParam = (filter: ContainerFilterProps): string => {
  return btoa(JSON.stringify(mapFilterToQuery(filter)));
};

export const parseContainerFilterParam = (filter: string | null): ContainerFilterProps => {
  if (filter !== null) {
    return mapQueryToFilter(JSON.parse(atob(filter)) as ContainerFilterQuery);
  } else {
    return {
      status: "Current",
    };
  }
};
