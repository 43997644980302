import { ContainerRow } from "src/components/containerList/containerList.tsx";
import { SubscriptionRow } from "src/components/subscriptionList/subscriptionList.tsx";

// Containerlist
export const loadContainerColumnSetting = (): (keyof ContainerRow)[] | null => {
  const setting = localStorage.getItem("containerColumnSetting");
  if (setting === null) {
    return null;
  }
  return JSON.parse(setting);
};

export const loadContainerColumnOrderSetting = (): (keyof ContainerRow)[] | null => {
  const setting = localStorage.getItem("containerColumnOrderSetting");
  if (setting === null) {
    return null;
  }
  return JSON.parse(setting);
};

export const loadContainerPageSize = (): number | null => {
  const pageSize = localStorage.getItem("containerPageSize");
  if (pageSize === null) {
    return null;
  }
  return parseInt(pageSize);
};

// Subscriptionlist
export const loadSubscriptionColumnSetting = (): (keyof SubscriptionRow)[] | null => {
  const setting = localStorage.getItem("subscriptionColumnSetting");
  if (setting === null) {
    return null;
  }
  return JSON.parse(setting);
};

export const loadSubscriptionColumnOrderSetting = (): (keyof SubscriptionRow)[] | null => {
  const setting = localStorage.getItem("subscriptionColumnOrderSetting");
  if (setting === null) {
    return null;
  }
  return JSON.parse(setting);
};

export const loadSubscriptionPageSize = (): number | null => {
  const pageSize = localStorage.getItem("subscriptionPageSize");
  if (pageSize === null) {
    return null;
  }
  return parseInt(pageSize);
};
