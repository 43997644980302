import { FC } from "react";
import { Helmet } from "react-helmet-async";

export const NotFoundPage: FC = () => {
  return (
    <>
      <Helmet>
        <title>iSEA Trace - Not Found</title>
      </Helmet>
      <div>The page you are looking for could not be found.</div>
    </>
  );
};
