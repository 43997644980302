import { FC } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { CCircle } from "react-bootstrap-icons";

import logo from "src/assets/isea.svg";

const footerLinks: { url: string; text: string }[] = [
  {
    text: "Imprint",
    url: "/imprint",
  },
  {
    text: "Contact",
    url: "/contact",
  },
];

export const Footer: FC = () => {
  return (
    <Navbar bg="primary">
      <Container fluid={true} className="px-4">
        <Navbar.Brand>
          <img src={logo} width="130" height="32" alt="" />
        </Navbar.Brand>
        <Nav className="d-none d-md-flex">
          {footerLinks.map((item, index) => (
            <Nav.Link key={`footer-link-${index}`} href={item.url}>
              {item.text}
            </Nav.Link>
          ))}
        </Nav>
        <Navbar.Text className="d-flex align-items-center">
          <CCircle />
          &nbsp;2024 IntelliWay GmbH
        </Navbar.Text>
      </Container>
    </Navbar>
  );
};
