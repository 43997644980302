import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { restrictToVerticalAxis, restrictToWindowEdges } from "@dnd-kit/modifiers";
import { SortableContext, arrayMove, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { Dropdown } from "react-bootstrap";

import { ColumnDropdownItem } from "src/components/columnDropdown/columnDropdownItem.tsx";

interface ColumnDropdownProps<T> {
  items: {
    key: T;
    value: string;
  }[];
  selectedColumns: T[];
  columnOrder: T[];
  setSelectedColumns: (columns: T[]) => void;
  setColumnOrder: (order: T[]) => void;
}

export const ColumnDropdown = <T extends string>({
  items,
  selectedColumns,
  columnOrder,
  setSelectedColumns,
  setColumnOrder,
}: ColumnDropdownProps<T>) => {
  const selectedColumnsCount = items.filter((c) => selectedColumns.includes(c.key)).length;
  const orderedColumns = items.toSorted((a, b) => columnOrder.indexOf(a.key) - columnOrder.indexOf(b.key));

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over !== null && active.id !== over.id) {
      const oldIndex = columnOrder.indexOf(active.id as T);
      const newIndex = columnOrder.indexOf(over.id as T);

      setColumnOrder(arrayMove(columnOrder, oldIndex, newIndex));
    }
  };

  const toggleColumn = (column: T): void => {
    if (selectedColumns.indexOf(column) >= 0) {
      setSelectedColumns(selectedColumns.filter((c) => c !== column));
    } else {
      setSelectedColumns([...selectedColumns, column]);
    }
  };

  return (
    <Dropdown autoClose="outside">
      <Dropdown.Toggle variant="primary" id="dropdown-basic">
        Select Columns ({selectedColumnsCount}/{items.length})
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <DndContext onDragEnd={handleDragEnd} modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}>
          <SortableContext items={orderedColumns.map((c) => c.key)} strategy={verticalListSortingStrategy}>
            {orderedColumns.map((c) => (
              <ColumnDropdownItem
                key={c.key}
                id={c.key}
                checked={selectedColumns.indexOf(c.key) >= 0}
                toggleColumn={() => toggleColumn(c.key)}
                value={c.value}
              />
            ))}
          </SortableContext>
        </DndContext>
      </Dropdown.Menu>
    </Dropdown>
  );
};
