import { useCallback, useMemo, useRef } from "react";
import {
  SetURLSearchParams,
  URLSearchParamsInit,
  createSearchParams,
  useLocation,
  useNavigate,
} from "react-router-dom";

export const parseSearchParamToNumber = (param: string | null): number | undefined => {
  if (param === null) {
    return undefined;
  }
  return parseInt(param);
};

function getSearchParamsForLocation(locationSearch: string, defaultSearchParams: URLSearchParams | null) {
  const searchParams = createSearchParams(locationSearch);

  if (defaultSearchParams) {
    defaultSearchParams.forEach((_, key) => {
      if (!searchParams.has(key)) {
        defaultSearchParams.getAll(key).forEach((value) => {
          searchParams.append(key, value);
        });
      }
    });
  }

  return searchParams;
}

export function useSearchParams(defaultInit?: URLSearchParamsInit): [URLSearchParams, SetURLSearchParams] {
  const defaultSearchParamsRef = useRef(createSearchParams(defaultInit));

  const location = useLocation();
  const searchParamsRef = useRef<URLSearchParams>();
  const searchParams = useMemo(() => {
    searchParamsRef.current = getSearchParamsForLocation(location.search, defaultSearchParamsRef.current);
    return searchParamsRef.current;
  }, [location.search]);

  const navigate = useNavigate();
  const setSearchParams = useCallback<SetURLSearchParams>(
    (nextInit, navigateOptions) => {
      const newSearchParams = createSearchParams(
        typeof nextInit === "function" ? nextInit(searchParamsRef.current || new URLSearchParams()) : nextInit
      );
      navigate("?" + newSearchParams, navigateOptions);
    },
    [navigate]
  );

  return [searchParams, setSearchParams];
}
