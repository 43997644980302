import { FC, Fragment, MouseEvent, useState } from "react";
import { Collapse, Table } from "react-bootstrap";
import { CaretDownFill, CaretUpFill } from "react-bootstrap-icons";

import { CopyableText } from "src/components/copyableText.tsx";
import { EventList } from "src/components/subscriptionList/eventList.tsx";
import { Container } from "src/model/subscription.ts";
import { compareStrings } from "src/utils/comparator.ts";
import { formatDateTime } from "src/utils/timeFormat.ts";

interface ContainerListProps {
  containers: Container[];
}

export const ContainerList: FC<ContainerListProps> = ({ containers }) => {
  const [openedContainers, setOpenedContainers] = useState<string[]>([]);

  const toggleContainerState = (event: MouseEvent<HTMLTableRowElement>, containerNumber: string) => {
    if (!(event.target instanceof HTMLAnchorElement) && !document.getSelection()?.toString())
      setOpenedContainers((prevState) => {
        const index = prevState.indexOf(containerNumber);
        if (index >= 0) {
          return prevState.toSpliced(index, 1);
        } else {
          return [...prevState, containerNumber];
        }
      });
  };

  return (
    <Table className="table-secondary m-0">
      <thead>
        <tr>
          <th>Container-No.</th>
          <th>Last Status</th>
          <th>Last Status Place</th>
          <th>Last Status Date</th>
          <th>Detention/Demurrage Pre Carriage</th>
          <th>Detention/Demurrage On Carriage</th>
          <th />
        </tr>
      </thead>
      <tbody className="table-group-divider">
        {containers
          .sort((c1, c2) => compareStrings(c1.containerNumber, c2.containerNumber))
          .map((container, index) => (
            <Fragment key={`container-${index}`}>
              <tr onClick={(event) => toggleContainerState(event, container.containerNumber)}>
                <td>
                  <CopyableText text={container.containerNumber} />
                </td>
                <td>{container.lastStatus?.status}</td>
                <td>{container.lastStatus?.location}</td>
                <td>{formatDateTime(container.lastStatus?.time)}</td>
                <td>{container.detentionDemurragePrecarriageDays}</td>
                <td>{container.detentionDemurrageOncarriageDays}</td>
                <td>
                  {container.containerEvents.length > 0 ? (
                    openedContainers.indexOf(container.containerNumber) >= 0 ? (
                      <CaretUpFill role="button" />
                    ) : (
                      <CaretDownFill role="button" />
                    )
                  ) : null}
                </td>
              </tr>
              {container.containerEvents.length > 0 && (
                <tr>
                  <td colSpan={7} className="p-0 border-bottom-0">
                    <Collapse
                      in={openedContainers.indexOf(container.containerNumber) >= 0}
                      mountOnEnter={true}
                      unmountOnExit={true}
                    >
                      <div className="border-bottom">
                        <div className="m-3">
                          <EventList events={container.containerEvents} />
                        </div>
                      </div>
                    </Collapse>
                  </td>
                </tr>
              )}
            </Fragment>
          ))}
      </tbody>
    </Table>
  );
};
