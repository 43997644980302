import { CustomerFilterProps } from "src/model/customer.ts";
import { UserState } from "src/store/auth.ts";

export const applyCustomerFilter = <F extends CustomerFilterProps>(user: UserState, filter: F): F => {
  if (user.role === "User") {
    const companyId = user.companyId;
    const customerId = user.customerId;
    if (companyId !== null && customerId !== null) {
      return {
        ...filter,
        customerId: customerId,
        companyId: companyId,
      };
    } else if (customerId !== null) {
      return {
        ...filter,
        customerId: customerId,
      };
    }
  }
  return { ...filter };
};
