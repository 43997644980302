import { FC } from "react";
import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { InfoCircle } from "react-bootstrap-icons";

import { ContainerEvent } from "src/model/subscription.ts";
import { formatDateTime } from "src/utils/timeFormat.ts";

interface EventListProps {
  events: ContainerEvent[];
}

export const EventList: FC<EventListProps> = ({ events }) => {
  return (
    <Table className="m-0">
      <thead>
        <tr>
          <th>Date</th>
          <th>Actual</th>
          <th>Location</th>
          <th>Milestone</th>
          <th>Category</th>
          <th>Carrier</th>
          <th>Type</th>
          <th>Voyage</th>
        </tr>
      </thead>
      <tbody className="table-group-divider">
        {events.map((event, index) => (
          <tr key={`event-${index}`}>
            <td>{formatDateTime(event.dateTime)}</td>
            <td>{event.dateTimeConfirmed ? "yes" : "no"}</td>
            <td>{event.location}</td>
            <td>
              {event.lineReported && event.lineReported.length > 0 ? (
                <OverlayTrigger
                  placement="auto"
                  overlay={<Tooltip style={{ whiteSpace: "pre-line" }}>{event.lineReported}</Tooltip>}
                >
                  <span className="d-inline-flex align-items-center">
                    {event.milestone}&nbsp;&nbsp;
                    <InfoCircle />
                  </span>
                </OverlayTrigger>
              ) : (
                <span className="d-inline-flex align-items-center">{event.milestone}</span>
              )}
            </td>
            <td>{event.milestoneCategory}</td>
            <td>{event.carrier}</td>
            <td>{event.carrierType}</td>
            <td>{event.voyage}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
