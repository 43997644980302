import { FC, Fragment, MouseEvent, useEffect, useState } from "react";
import { Collapse, Table } from "react-bootstrap";
import { ArrowRepeat, CaretDownFill, CaretUpFill, CheckCircleFill, XCircleFill } from "react-bootstrap-icons";

import { HttpError } from "src/api/httpError.ts";
import { retrySubscriptionRegistration } from "src/api/subscriptions.ts";
import { ConfirmModal } from "src/components/confirmModal.tsx";
import { SubscriptionLogEventList } from "src/components/subscriptionLogList/subscriptionLogEventList.tsx";
import { ShipmentRegistrationLog } from "src/model/subscriptionLog.ts";
import { useToasts } from "src/store/store.ts";
import { formatDateTime } from "src/utils/timeFormat.ts";

interface SubscriptionLogListProps {
  subscriptionLogs: ShipmentRegistrationLog[];
  reloadSubscriptionLogs: () => void;
}

export const SubscriptionLogList: FC<SubscriptionLogListProps> = ({ subscriptionLogs, reloadSubscriptionLogs }) => {
  const [openedSubscriptionLogs, setOpenedSubscriptionLogs] = useState<string[]>([]);
  const [subscriptionToRetry, setSubscriptionToRetry] = useState<ShipmentRegistrationLog>();
  const { showToast } = useToasts();

  useEffect(() => {
    setOpenedSubscriptionLogs([]);
  }, [subscriptionLogs]);

  const toggleSubscriptionLogState = (event: MouseEvent<HTMLTableRowElement>, subscriptionId: string) => {
    if (!(event.target instanceof HTMLAnchorElement) && !document.getSelection()?.toString())
      setOpenedSubscriptionLogs((prevState) => {
        const index = prevState.indexOf(subscriptionId);
        if (index >= 0) {
          return prevState.toSpliced(index, 1);
        } else {
          return [...prevState, subscriptionId];
        }
      });
  };

  const confirmRetry = async () => {
    if (subscriptionToRetry === undefined) {
      return;
    }
    try {
      await retrySubscriptionRegistration(subscriptionToRetry.subscriptionId);
      reloadSubscriptionLogs();
      showToast("Registration of subscription will be retried.");
    } catch (error) {
      if (error instanceof HttpError) {
        showToast("An error occurred: " + error.message);
      } else {
        console.error(error);
      }
    }
    setSubscriptionToRetry(undefined);
  };

  const onClickRetrySubscription = async (event: MouseEvent, shipmentRegistrationLog: ShipmentRegistrationLog) => {
    event.stopPropagation();
    event.preventDefault();

    setSubscriptionToRetry(shipmentRegistrationLog);
  };

  if (subscriptionLogs.length === 0) {
    return <div>No subscription logs found.</div>;
  }

  return (
    <>
      <ConfirmModal
        title="Retry subscription"
        body="Do you really want to retry registration of subscription?"
        show={subscriptionToRetry !== undefined}
        confirmButtonText="Retry"
        cancelButtonText="Cancel"
        handleConfirm={confirmRetry}
        handleClose={() => setSubscriptionToRetry(undefined)}
      />
      <Table>
        <thead>
          <tr>
            <th>Registered</th>
            <th>Registration State</th>
            <th>Error Message</th>
            <th>Subscription Id</th>
            <th>Created At</th>
            <th>Created By</th>
            <th>Customer Name</th>
            <th>Shipping Line</th>
            <th>MBL Number</th>
            <th>Booking Number</th>
            <th>Done</th>
            <th>External References</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {subscriptionLogs.map((sl) => (
            <Fragment key={`tr-${sl.subscriptionId}`}>
              <tr onClick={(event) => toggleSubscriptionLogState(event, sl.subscriptionId)}>
                <td>{sl.successfullyRegistered ? <CheckCircleFill color="green" /> : <XCircleFill color="red" />}</td>
                <td>{sl.registrationState}</td>
                <td>{sl.shipmentRegistrationErrorMessage}</td>
                <td>{sl.subscriptionId}</td>
                <td>{formatDateTime(sl.createdAt)}</td>
                <td>{sl.createdBy}</td>
                <td>{sl.customerName}</td>
                <td>{sl.scac}</td>
                <td>{sl.billOfLading}</td>
                <td>{sl.bookingNumber}</td>
                <td>{sl.done ? <CheckCircleFill color="green" /> : <XCircleFill color="red" />}</td>
                <td style={{ whiteSpace: "pre-line" }}>{sl.externalReferences.join("\n")}</td>
                <td>
                  <div className="d-flex align-items-center justify-content-between">
                    {sl.shipmentRegistrationEvents.length > 0 ? (
                      openedSubscriptionLogs.indexOf(sl.subscriptionId) >= 0 ? (
                        <CaretUpFill role="button" />
                      ) : (
                        <CaretDownFill role="button" />
                      )
                    ) : null}
                    {sl.registrationState === "REGISTRATION_FAILED" ? (
                      <span role="button" onClick={(event) => onClickRetrySubscription(event, sl)}>
                        <ArrowRepeat size={18} />
                      </span>
                    ) : (
                      <span className="text-secondary">
                        <ArrowRepeat size={18} />
                      </span>
                    )}
                  </div>
                </td>
              </tr>
              {sl.shipmentRegistrationEvents.length > 0 && (
                <tr>
                  <td colSpan={13} className="p-0 border-bottom-0">
                    <Collapse
                      in={openedSubscriptionLogs.indexOf(sl.subscriptionId) >= 0}
                      mountOnEnter={true}
                      unmountOnExit={true}
                    >
                      <div className="border-bottom">
                        <div className="m-3">
                          <SubscriptionLogEventList subscriptionLogEvents={sl.shipmentRegistrationEvents} />
                        </div>
                      </div>
                    </Collapse>
                  </td>
                </tr>
              )}
            </Fragment>
          ))}
        </tbody>
      </Table>
    </>
  );
};
