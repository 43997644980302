import { FC } from "react";
import { ArrowLeftShort, ArrowRightShort } from "react-bootstrap-icons";

export const DaysOfDelay: FC<{ days: number }> = ({ days }) => {
  const commonClassNames = "fw-bold d-inline-flex align-items-center";

  if (days > 0) {
    // later
    return (
      <div className={`text-danger ${commonClassNames}`}>
        {days}&nbsp;&nbsp;
        <ArrowRightShort size={24} />
      </div>
    );
  }
  if (days < 0) {
    // earlier
    return (
      <div className={`text-success ${commonClassNames}`}>
        <ArrowLeftShort size={24} />
        &nbsp;&nbsp;{days}
      </div>
    );
  }

  return <div className={`text-secondary ${commonClassNames}`}>{days}</div>;
};
