import { FC } from "react";
import { Helmet } from "react-helmet-async";

import { UserList } from "src/components/userList.tsx";

export const UserListPage: FC = () => {
  return (
    <>
      <Helmet>
        <title>iSEA Trace - Users</title>
      </Helmet>
      <UserList />
    </>
  );
};
