export const addOneDay = (millis?: number): number | undefined => {
  if (millis === undefined) {
    return undefined;
  }

  return millis + 24 * 60 * 60 * 1000;
};

export const getStartOfQuarter = (date: Date): Date => {
  const quarter = Math.floor((date.getMonth() + 3) / 3);
  return new Date(Date.UTC(date.getUTCFullYear(), (quarter - 1) * 3, 1));
};

export const getDateFormatPattern = () => {
  const getPatternForPart = (part: Intl.DateTimeFormatPart) => {
    switch (part.type) {
      case "day":
        return "d".repeat(part.value.length);
      case "month":
        return "m".repeat(part.value.length);
      case "year":
        return "y".repeat(part.value.length);
      case "literal":
        return part.value;
      default:
        console.error("Unsupported date part", part);
        return "";
    }
  };

  return new Intl.DateTimeFormat(Intl.DateTimeFormat().resolvedOptions().locale)
    .formatToParts(new Date("2021-12-31"))
    .map(getPatternForPart)
    .join("");
};

export function dateToUtc(date: Date): Date;
export function dateToUtc(date: null): undefined;

export function dateToUtc(date: Date | null): Date | undefined {
  if (date === null) {
    return undefined;
  }

  return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
}
