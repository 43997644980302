import { SubscriptionLogFilterProps } from "src/model/subscriptionLog.ts";

interface SubscriptionLogFilterQuery {
  query?: string;
}

const mapFilterToQuery = (filter: SubscriptionLogFilterProps): SubscriptionLogFilterQuery => {
  return {
    query: filter.query,
  };
};

const mapQueryToFilter = (query: SubscriptionLogFilterQuery): SubscriptionLogFilterProps => {
  return {
    query: query.query,
  };
};

export const buildSubscriptionLogFilterParam = (filter: SubscriptionLogFilterProps): string => {
  return btoa(JSON.stringify(mapFilterToQuery(filter)));
};

export const parseSubscriptionLogFilterParam = (filter: string | null): SubscriptionLogFilterProps => {
  if (filter !== null) {
    return mapQueryToFilter(JSON.parse(atob(filter)) as SubscriptionLogFilterQuery);
  } else {
    return {};
  }
};
