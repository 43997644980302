import { debounce } from "lodash";
import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Fade, Form, InputGroup, Spinner } from "react-bootstrap";
import { Search } from "react-bootstrap-icons";

import { fetchSubscriptionLogs } from "src/api/subscriptions.ts";
import { Pagination } from "src/components/pagination.tsx";
import { SubscriptionLogList } from "src/components/subscriptionLogList/subscriptionLogList.tsx";
import { usePaginationContext } from "src/components/subscriptionLogList/subscriptionLogListPaginationContext.ts";
import { PageRequest } from "src/model/pageRequest.ts";
import { PaginatedResponse } from "src/model/paginatedResponse.ts";
import { ShipmentRegistrationLog } from "src/model/subscriptionLog.ts";

import styles from "src/components/customerList.module.scss";

export const FilteredSubscriptionLogList: FC = () => {
  const [subscriptionLogs, setSubscriptionLogs] = useState<PaginatedResponse<ShipmentRegistrationLog> | undefined>();
  const [loading, setLoading] = useState(true);
  const { pagination, filter, setPagination } = usePaginationContext();
  const [search, setSearch] = useState(filter.query ?? "");

  const loadSubscriptionLogs = useCallback((query: string | undefined, pagination: PageRequest) => {
    setLoading(true);
    fetchSubscriptionLogs(query, pagination)
      .then((response) => {
        setSubscriptionLogs(response);
      })
      .catch((error) => {
        console.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    loadSubscriptionLogs(filter.query, pagination);
  }, [loadSubscriptionLogs, pagination, filter]);

  const onPageChange = (offset: number) => {
    setPagination((prevState) => ({ ...prevState, pagination: { ...prevState.pagination, offset: offset } }));
  };

  const onPageSizeChange = (limit: number) => {
    setPagination((prevState) => ({ ...prevState, pagination: { ...prevState.pagination, limit: limit, offset: 0 } }));
  };

  const onSearch = useCallback(
    (search: string) => {
      const query = search.length > 0 ? search : undefined;
      setPagination((prevState) => ({
        ...prevState,
        filter: { ...prevState.filter, query: query },
        pagination: { ...prevState.pagination, offset: 0 },
      }));
    },
    [setPagination]
  );

  const debouncedSearch = useMemo(() => {
    return debounce(onSearch, 500);
  }, [onSearch]);

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearch(value);

    debouncedSearch(value);
  };

  if (subscriptionLogs === undefined) {
    return null;
  }

  return (
    <>
      <div className="d-flex mb-3">
        <Col xl={6} xxl={4}>
          <InputGroup>
            <Form.Control value={search} onChange={onSearchChange} placeholder="Search" />
            <Button onClick={() => onSearch(search)} variant="outline-primary">
              <Search />
            </Button>
          </InputGroup>
        </Col>

        <div className="flex-grow-1" />
      </div>
      <Fade in={loading} unmountOnExit={true} mountOnEnter={true}>
        <div className={`d-flex justify-content-center align-items-center ${styles.block}`}>
          <div className={`w-100 h-100 ${styles.fade}`}></div>
          <Spinner />
        </div>
      </Fade>
      <SubscriptionLogList
        subscriptionLogs={subscriptionLogs.items}
        reloadSubscriptionLogs={() => loadSubscriptionLogs(filter.query, pagination)}
      />
      <Pagination
        totalItems={subscriptionLogs.totalItems}
        offset={pagination.offset}
        limit={pagination.limit}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
      />
    </>
  );
};
