import { isEqual } from "lodash";
import { FC, Fragment, ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";

import { fetchSubscriptionFilterOptions } from "src/api/subscriptions.ts";
import { CompanyFilter } from "src/components/filter/companyFilter.tsx";
import { CustomerFilter } from "src/components/filter/customerFilter.tsx";
import { FilterItem } from "src/components/filter/filterItem.tsx";
import { RangeFilter, RangeFilterQuickOption } from "src/components/filter/rangeFilter.tsx";
import { FilteredSubscriptionList } from "src/components/subscriptionList/filteredSubscriptionList.tsx";
import {
  buildSubscriptionFilterParam,
  parseSubscriptionFilterParam,
} from "src/components/subscriptionList/subscriptionFilterUtils.ts";
import { Provider, usePaginationContext } from "src/components/subscriptionList/subscriptionListPaginationContext.ts";
import {
  SubscriptionFilterOptions,
  SubscriptionFilterProps,
  SubscriptionPagination,
  SubscriptionSortBy,
} from "src/model/subscription.ts";
import { updateUserPreferences } from "src/store/auth.ts";
import { useAppDispatch, useUserPreferences, useUserState } from "src/store/store.ts";
import { dateToUtc, getStartOfQuarter } from "src/utils/datetime.ts";
import { buildPortString } from "src/utils/ports.ts";
import { parseSearchParamToNumber, useSearchParams } from "src/utils/searchParams.ts";

const defaultPagination: SubscriptionPagination = {
  limit: 10,
  offset: 0,
  sortBy: "EtaPortOfDischarge",
  sortDir: "Desc",
};

interface FilterAndPagination {
  pagination: SubscriptionPagination;
  filter: SubscriptionFilterProps;
}

const parsePaginationFromUrl = (searchParams: URLSearchParams, pageSize?: number): FilterAndPagination => {
  const filter = searchParams.get("filter");
  const offset = parseSearchParamToNumber(searchParams.get("offset")) ?? defaultPagination.offset;
  const limit = parseSearchParamToNumber(searchParams.get("limit")) ?? pageSize ?? defaultPagination.limit;
  const sortBy = (searchParams.get("sortBy") as SubscriptionSortBy) ?? defaultPagination.sortBy;
  const sortDir = (searchParams.get("sortDir") as SubscriptionPagination["sortDir"]) ?? defaultPagination.sortDir;

  return {
    pagination: {
      offset: offset,
      limit: limit,
      sortDir: sortDir,
      sortBy: sortBy,
    },
    filter: parseSubscriptionFilterParam(filter),
  };
};

const updateSearchParams = (
  prev: URLSearchParams,
  filter: SubscriptionFilterProps,
  pagination: SubscriptionPagination
) => {
  Object.entries(pagination).forEach(([key, value]) => {
    if (value !== undefined) {
      if (prev.get(key) !== value) {
        prev.set(key, value);
      }
    } else {
      prev.delete(key);
    }
  });
  if (Object.keys(filter).length === 0) {
    prev.delete("filter");
  } else {
    const value = buildSubscriptionFilterParam(filter);
    if (prev.get("filter") !== value) {
      prev.set("filter", value);
    }
  }
};

export const SubscriptionListPage: FC = () => {
  const preferences = useUserPreferences();
  const [searchParams, setSearchParams] = useSearchParams();
  const [{ pagination, filter }, setProps] = useState<FilterAndPagination>(() =>
    parsePaginationFromUrl(searchParams, preferences?.subscriptionPageSize)
  );
  const divRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setProps((prev) => {
      const { pagination, filter } = parsePaginationFromUrl(searchParams);
      if (isEqual(prev.pagination, pagination) && isEqual(prev.filter, filter)) {
        return prev;
      }
      return {
        pagination: isEqual(prev.pagination, pagination) ? prev.pagination : pagination,
        filter: isEqual(prev.filter, filter) ? prev.filter : filter,
      };
    });
    const { current } = divRef;
    if (current !== null) {
      current.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, [searchParams]);

  const setPagination = useMemo(
    () => (p: FilterAndPagination | ((state: FilterAndPagination) => FilterAndPagination)) => {
      setSearchParams((prev) => {
        const result = typeof p === "function" ? p(parsePaginationFromUrl(prev)) : { ...p };
        updateSearchParams(prev, result.filter, result.pagination);
        dispatch(updateUserPreferences({ subscriptionPageSize: result.pagination.limit }));
        return prev;
      });
    },
    [dispatch, setSearchParams]
  );

  useEffect(() => {
    setSearchParams(
      (prev) => {
        if (prev.size === 0) {
          const { pagination, filter } = parsePaginationFromUrl(prev);
          updateSearchParams(prev, filter, pagination);
        }
        return prev;
      },
      { replace: true }
    );
  }, [setSearchParams]);

  return (
    <>
      <Helmet>
        <title>iSEA Trace - Subscriptions</title>
      </Helmet>
      <Provider
        value={{
          pagination: pagination,
          filter: filter,
          setPagination: setPagination,
        }}
      >
        <div className="d-flex" style={{ minHeight: "100%", scrollMarginTop: "1.5rem" }} ref={divRef}>
          <div className="ms-n4 p-4 mt-n4 mb-n4 border-end border-secondary-subtle">
            <div style={{ width: "220px" }}>
              <SubscriptionFilter />
            </div>
          </div>

          <div className="flex-grow-1 ms-4">
            <FilteredSubscriptionList />
          </div>
        </div>
      </Provider>
    </>
  );
};

const SubscriptionFilter: FC = () => {
  const user = useUserState();
  const [filterOptions, setFilterOptions] = useState<SubscriptionFilterOptions>();
  const { filter, setPagination } = usePaginationContext();

  useEffect(() => {
    fetchSubscriptionFilterOptions(filter).then(setFilterOptions);
  }, [filter]);

  const setFilter = <K extends keyof SubscriptionFilterProps>(key: K) => {
    return (value: SubscriptionFilterProps[K]) => {
      setPagination((prevState) => {
        const newFilter: SubscriptionFilterProps = { ...prevState.filter };
        if (
          (Array.isArray(value) && value.length === 0) ||
          value === undefined ||
          (typeof value === "string" && value === "")
        ) {
          delete newFilter[key];
        } else {
          newFilter[key] = value;
        }

        return {
          ...prevState,
          filter: { ...newFilter },
          pagination: { ...prevState.pagination, offset: 0 },
        };
      });
    };
  };

  if (filterOptions === undefined) {
    return null;
  }

  const filterItems: { key: string; filter: ReactNode }[] = [];
  if (user.role === "Admin") {
    filterItems.push(
      {
        key: "customerId",
        filter: <CustomerFilter onChangeCustomerId={setFilter("customerId")} selectedCustomerId={filter.customerId} />,
      },
      {
        key: "companyId",
        filter: (
          <CompanyFilter
            onChangeCompanyId={setFilter("companyId")}
            selectedCompanyId={filter.companyId}
            selectedCustomerId={filter.customerId}
          />
        ),
      }
    );
  }

  const buildEtaQuickOptions = (): RangeFilterQuickOption[] => {
    const today = new Date();

    const startOfCurrentQuarter = getStartOfQuarter(today);
    const startOfLastQuarter = dateToUtc(
      new Date(startOfCurrentQuarter.getUTCFullYear(), startOfCurrentQuarter.getUTCMonth() - 3, 1)
    );
    const endOfLastQuarter = dateToUtc(
      new Date(startOfCurrentQuarter.getUTCFullYear(), startOfCurrentQuarter.getUTCMonth(), 0)
    );

    return [
      {
        label: "Current Month",
        range: [
          dateToUtc(new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), 1))),
          dateToUtc(new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth() + 1, 0))),
        ],
      },
      {
        label: "Last Month",
        range: [
          dateToUtc(new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth() - 1, 1))),
          dateToUtc(new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), 0))),
        ],
      },
      {
        label: "Last Quarter",
        range: [startOfLastQuarter, endOfLastQuarter],
      },
      {
        label: "This year",
        range: [dateToUtc(new Date(today.getUTCFullYear(), 0, 1)), undefined],
      },
      {
        label: "Upcoming",
        range: [
          dateToUtc(new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate()))),
          undefined,
        ],
      },
    ];
  };

  if (
    filterOptions.portOfLoadingCodes.length > 0 ||
    (filter.portOfLoadingCodes && filter.portOfLoadingCodes.length > 0)
  ) {
    filterItems.push({
      key: "port-of-loading",
      filter: (
        <FilterItem
          title="Port of Loading"
          filterOptions={filterOptions.portOfLoadingCodes}
          selectedFilterOptions={filter.portOfLoadingCodes || []}
          onChangeFilter={setFilter("portOfLoadingCodes")}
          valueMapper={(filter) => buildPortString(filter.value, filter.key)}
        />
      ),
    });
  }
  if (
    filterOptions.portOfDischargeCodes.length > 0 ||
    (filter.portOfDischargeCodes && filter.portOfDischargeCodes.length > 0)
  ) {
    filterItems.push({
      key: "port-of-discharge",
      filter: (
        <FilterItem
          title="Port of Discharge"
          filterOptions={filterOptions.portOfDischargeCodes}
          selectedFilterOptions={filter.portOfDischargeCodes || []}
          onChangeFilter={setFilter("portOfDischargeCodes")}
          valueMapper={(filter) => buildPortString(filter.value, filter.key)}
        />
      ),
    });
  }
  if (filterOptions.scacs.length > 0 || (filter.scacs && filter.scacs.length > 0)) {
    filterItems.push({
      key: "shipping-line",
      filter: (
        <FilterItem
          title="Shipping Line"
          filterOptions={filterOptions.scacs}
          selectedFilterOptions={filter.scacs || []}
          onChangeFilter={setFilter("scacs")}
          valueMapper={(filter) => `${filter.value} (${filter.key})`}
        />
      ),
    });
  }

  filterItems.push({
    key: "eta-port-of-discharge",
    filter: (
      <RangeFilter
        title="Eta Port of Discharge"
        selectedDate={filter.etaPortOfDischarge}
        onChangeDate={setFilter("etaPortOfDischarge")}
        quickOptions={buildEtaQuickOptions()}
      />
    ),
  });

  if (filterOptions.status.length > 0 || (filter.status && filter.status.length > 0)) {
    filterItems.push({
      key: "stats",
      filter: (
        <FilterItem
          title="Status"
          filterOptions={filterOptions.status}
          selectedFilterOptions={filter.status ? [filter.status] : []}
          onChangeFilter={(values) => setFilter("status")(values[0])}
          radio={true}
          expandable={false}
        />
      ),
    });
  }

  return (
    <>
      <h4 className="mb-4 mt-2">Filter</h4>
      {filterItems.map((item, index) => (
        <Fragment key={`filter-${item.key}`}>
          {index > 0 && <div className="border-bottom mb-3 mt-3" />}
          {item.filter}
        </Fragment>
      ))}
    </>
  );
};
