import { SubscriptionFilterProps } from "src/model/subscription.ts";
import { SubscriptionStatus } from "src/model/subscriptionStatus.ts";

interface SubscriptionFilterQuery {
  custId?: string;
  compId?: string;
  query?: string;
  excludeFailed?: boolean;
  portOfLoadings?: string[];
  portOfDischarges?: string[];
  scacs?: string[];
  etaFrom?: number;
  etaTo?: number;
  status?: SubscriptionStatus;
}

const numberToDate = (n?: number): Date | undefined => {
  if (n === undefined) {
    return undefined;
  }

  return new Date(n);
};

const mapFilterToQuery = (filter: SubscriptionFilterProps): SubscriptionFilterQuery => {
  return {
    custId: filter.customerId,
    compId: filter.companyId,
    query: filter.query,
    excludeFailed: filter.excludeFailedSubscriptions,
    portOfLoadings: filter.portOfLoadingCodes,
    portOfDischarges: filter.portOfDischargeCodes,
    scacs: filter.scacs,
    etaFrom: filter.etaPortOfDischarge?.at(0)?.getTime(),
    etaTo: filter.etaPortOfDischarge?.at(1)?.getTime(),
    status: filter.status,
  };
};

const mapQueryToFilter = (query: SubscriptionFilterQuery): SubscriptionFilterProps => {
  return {
    customerId: query.custId,
    companyId: query.compId,
    query: query.query,
    excludeFailedSubscriptions: query.excludeFailed,
    portOfLoadingCodes: query.portOfLoadings,
    portOfDischargeCodes: query.portOfDischarges,
    scacs: query.scacs,
    etaPortOfDischarge:
      query.etaFrom || query.etaTo ? [numberToDate(query.etaFrom), numberToDate(query.etaTo)] : undefined,
    status: query.status,
  };
};

export const buildSubscriptionFilterParam = (filter: SubscriptionFilterProps): string => {
  return btoa(JSON.stringify(mapFilterToQuery(filter)));
};

export const parseSubscriptionFilterParam = (filter: string | null): SubscriptionFilterProps => {
  if (filter !== null) {
    return mapQueryToFilter(JSON.parse(atob(filter)) as SubscriptionFilterQuery);
  } else {
    return { status: "Current" };
  }
};
