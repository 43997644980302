import { FC } from "react";
import { Helmet } from "react-helmet-async";

import { Dashboard } from "src/components/dashboard.tsx";

export const Home: FC = () => {
  return (
    <>
      <Helmet>
        <title>iSEA Trace - Dashboard</title>
      </Helmet>
      <Dashboard />
    </>
  );
};
