import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ReactNode } from "react";

export interface Dialog {
  title: string;
  message: ReactNode;
}

export interface DialogState {
  dialog: Dialog | null;
}

const initialState: DialogState = {
  dialog: null,
};

export const dialogSlice = createSlice({
  name: "dialog",
  initialState: initialState,
  reducers: {
    showDialog: (_, action: PayloadAction<Dialog>) => {
      return { dialog: action.payload };
    },
    hideDialog: () => {
      return { dialog: null };
    },
  },
});

export const dialogReducer = dialogSlice.reducer;
export const showDialog = dialogSlice.actions.showDialog;
export const hideDialog = dialogSlice.actions.hideDialog;
