import { FC } from "react";

import { CompanyDropdown } from "src/components/companyDropdown.tsx";

interface CompanyFilterProps {
  onChangeCompanyId: (customerId: string | undefined) => void;
  selectedCustomerId?: string;
  selectedCompanyId?: string;
}

export const CompanyFilter: FC<CompanyFilterProps> = ({ onChangeCompanyId, selectedCustomerId, selectedCompanyId }) => {
  return (
    <>
      <h5 className="d-flex justify-content-between align-items-center">Company</h5>
      <CompanyDropdown
        customerId={selectedCustomerId}
        companyId={selectedCompanyId}
        onChangeCompanyId={onChangeCompanyId}
        size={"sm"}
      />
    </>
  );
};
