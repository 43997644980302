import { FC, MouseEvent } from "react";
import { Copy } from "react-bootstrap-icons";

import { useToasts } from "src/store/store.ts";

import styles from "./copyableText.module.scss";

export const CopyableText: FC<{ text: string | null }> = ({ text }) => {
  const { showToast } = useToasts();

  if (text === null) {
    return null;
  }

  const onClick = async (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    await navigator.clipboard.writeText(text);

    showToast(`Copied "${text}" to clipboard`);
  };

  return (
    <span className={`d-inline-flex align-items-center ${styles.copyableText}`}>
      {text}
      <Copy onClick={onClick} size={14} className="ms-1" title="Copy to clipboard" />
    </span>
  );
};
