import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";

import { HttpError } from "src/api/httpError.ts";
import { fetchShippingLines } from "src/api/shippingLines.ts";
import { fetchSubscription } from "src/api/subscriptions.ts";
import { SubscriptionForm } from "src/components/subscriptionForm.tsx";
import { ShippingLine } from "src/model/shippingLine.ts";
import { SimpleSubscription } from "src/model/subscription.ts";

export const SubscriptionDetailsPage: FC = () => {
  const { subscriptionId } = useParams();
  const [subscription, setSubscription] = useState<SimpleSubscription | null | undefined>();
  const [error, setError] = useState<string | null>(null);
  const [shippingLines, setShippingLines] = useState<ShippingLine[]>();

  useEffect(() => {
    fetchShippingLines().then(setShippingLines);
  }, [setShippingLines]);

  useEffect(() => {
    if (subscriptionId !== undefined) {
      if (subscriptionId === "new") {
        setSubscription(null);
      } else {
        fetchSubscription(subscriptionId)
          .then((subscription) => {
            setSubscription(subscription);
          })
          .catch((error) => {
            if (error instanceof HttpError) {
              if (error.statusCode === 404) {
                setError("Subscription not found.");
              } else {
                setError(error.message);
              }
            } else {
              setError("An unknown error occurred. Please try again later.");
            }
          });
      }
    }
  }, [subscriptionId]);

  if (error !== null) {
    return (
      <>
        <Helmet>
          <title>iSEA Trace - Subscription Details</title>
        </Helmet>
        <div>{error}</div>
      </>
    );
  }

  if (subscription === undefined || shippingLines === undefined) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>iSEA Trace - {subscription === null ? "New" : "Edit"} Subscription</title>
      </Helmet>
      <SubscriptionForm subscription={subscription} shippingLines={shippingLines} />
    </>
  );
};
