import { FC } from "react";
import { Button, Modal } from "react-bootstrap";

interface ConfirmModalProps {
  show: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  title: string;
  body: string;
  cancelButtonText: string;
  confirmButtonText: string;
}

export const ConfirmModal: FC<ConfirmModalProps> = ({
  show,
  handleClose,
  handleConfirm,
  body,
  confirmButtonText,
  cancelButtonText,
  title,
}) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {cancelButtonText}
        </Button>
        <Button variant="danger" onClick={handleConfirm}>
          {confirmButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
