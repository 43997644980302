import { useEffect, useMemo, useState } from "react";
import { Collapse, Form } from "react-bootstrap";
import { CaretDownFill, CaretUpFill } from "react-bootstrap-icons";

import { FilterOptionEntry } from "src/model/filter.ts";

interface FilterItemProps<T extends string> {
  title: string;
  filterOptions: FilterOptionEntry<T>[];
  selectedFilterOptions: T[];
  onChangeFilter: (filter: T[]) => void;
  valueMapper?: (entry: FilterOptionEntry) => string;
  radio?: boolean;
  expandable?: boolean;
}

export const FilterItem = <T extends string>({
  title,
  filterOptions,
  selectedFilterOptions,
  onChangeFilter,
  radio,
  expandable = true,
  valueMapper = (entry) => entry.value,
}: FilterItemProps<T>) => {
  const [itemsToShow, setItemsToShow] = useState<number>(10);
  const [show, setShow] = useState<boolean>(!expandable);

  useEffect(() => {
    setItemsToShow(10);
  }, [filterOptions]);

  const items = useMemo(() => {
    const options: { key: T; value: string; count: number; selected: boolean }[] = [];

    for (const selectedFilterOption of selectedFilterOptions) {
      const entry = filterOptions.find((e) => e.key === selectedFilterOption);
      if (entry !== undefined) {
        options.push({
          key: entry.key,
          value: valueMapper(entry),
          count: entry.count,
          selected: true,
        });
      } else {
        options.push({
          key: selectedFilterOption,
          value: selectedFilterOption,
          count: 0,
          selected: true,
        });
      }
    }

    let addedItems = options.length;
    for (const item of filterOptions.filter((k) => selectedFilterOptions.indexOf(k.key) < 0)) {
      if (expandable && addedItems >= itemsToShow) {
        break;
      }
      options.push({
        key: item.key,
        value: valueMapper(item),
        count: item.count,
        selected: false,
      });
      addedItems++;
    }

    // quick fix to sort radio in the same order as the incoming filter items
    if (radio) {
      options.sort(
        (a, b) => filterOptions.findIndex((f) => f.key === a.key) - filterOptions.findIndex((f) => f.key === b.key)
      );
    }

    return options;
  }, [filterOptions, selectedFilterOptions, itemsToShow, valueMapper, radio, expandable]);

  const toggleFilter = (item: T) => {
    if (radio) {
      onChangeFilter([item]);
    } else {
      if (selectedFilterOptions.indexOf(item) < 0) {
        onChangeFilter([...selectedFilterOptions, item]);
      } else {
        onChangeFilter(selectedFilterOptions.filter((s) => s !== item));
      }
    }
  };

  if (items.length === 0) {
    return null;
  }

  return (
    <>
      <h5
        onClick={radio ? undefined : () => setShow((prevState) => !prevState)}
        className="d-flex justify-content-between align-items-center"
        role={radio ? undefined : "button"}
      >
        <span>{title}</span>
        {expandable && (show ? <CaretUpFill size={14} /> : <CaretDownFill size={14} />)}
      </h5>
      <Collapse in={show}>
        <div>
          {items.map(({ key, value, selected, count }, index) => (
            <Form.Check type="checkbox" id={key} key={`fi-${key}-${index}`}>
              <Form.Check.Input
                disabled={count === 0}
                type={radio ? "radio" : "checkbox"}
                checked={selected}
                onChange={() => toggleFilter(key)}
              />
              <Form.Check.Label className="d-flex justify-content-between">
                <div className="text-truncate me-2">{value}</div>
                <div>{count}</div>
              </Form.Check.Label>
            </Form.Check>
          ))}
          {expandable && itemsToShow < filterOptions.length ? (
            <div onClick={() => setItemsToShow((prevState) => prevState + 5)} className="text-center" role="button">
              Show more
            </div>
          ) : null}
        </div>
      </Collapse>
    </>
  );
};
