import moment from "moment";

export const formatDateTime = (date: Date | number | null | undefined): string => {
  if (date === null || date === undefined) {
    return "";
  }
  return moment.utc(date).format("DD.MM.YYYY HH:mm:ss");
};

export const formatDate = (date: Date | number | null | undefined): string => {
  if (date === null || date === undefined) {
    return "";
  }
  return moment.utc(date).format("DD.MM.YYYY");
};
