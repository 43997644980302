import { FC, useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";

import { createCompany, createCustomer, fetchCustomerDetails, updateCustomer } from "src/api/customers.ts";
import { CustomerForm } from "src/components/customerForm.tsx";
import { Customer, CustomerSaveRequest } from "src/model/customer.ts";

export const CustomerDetailsPage: FC = () => {
  const { customerId, companyId } = useParams();
  const [customer, setCustomer] = useState<Customer | null | undefined>();

  useEffect(() => {
    const id = companyId ?? customerId;

    if (id !== undefined) {
      if (id === "new") {
        setCustomer(null);
      } else {
        fetchCustomerDetails(id).then((customer) => {
          setCustomer(customer);
        });
      }
    }
  }, [customerId, companyId]);

  const saveCustomer = useCallback(
    async (customerSaveRequest: CustomerSaveRequest) => {
      if (companyId === undefined && customerId !== undefined && customerId !== "new") {
        await updateCustomer(customerId, customerSaveRequest);
      } else if (companyId !== undefined && companyId !== "new") {
        await updateCustomer(companyId, customerSaveRequest);
      } else if (companyId === "new" && customerId !== undefined && customerId !== "new") {
        await createCompany(customerId, customerSaveRequest);
      } else if (companyId === undefined && customerId !== undefined && customerId === "new") {
        await createCustomer(customerSaveRequest);
      } else {
        console.error(`Invalid combination of customer id (${customerId}) and company id (${companyId})`);
      }
    },
    [customerId, companyId]
  );

  if (customer === undefined) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>iSEA Trace - {customer === null ? "New Customer" : customer.companyName}</title>
      </Helmet>
      <CustomerForm saveCustomer={saveCustomer} customer={customer} />
    </>
  );
};
