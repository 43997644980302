import { FC } from "react";
import { Toast, ToastContainer } from "react-bootstrap";

import { useAppDispatch, useToasts } from "src/store/store.ts";
import { hideToast, removeToast } from "src/store/toast.ts";

export const ToastMessageContainer: FC = () => {
  const { toastMessages } = useToasts();
  const dispatch = useAppDispatch();

  const onEntered = (id: string) => {
    setTimeout(() => {
      dispatch(hideToast(id));
    }, 5000);
  };

  const onExited = (id: string) => {
    dispatch(removeToast(id));
  };

  return (
    <ToastContainer position="bottom-center">
      {toastMessages.map(({ show, message, id }) => (
        <Toast
          key={`toast-${id}`}
          show={show}
          onEntered={() => onEntered(id)}
          onExited={() => onExited(id)}
          bg="primary"
        >
          <Toast.Body className="text-white fw-bolder">{message}</Toast.Body>
        </Toast>
      ))}
    </ToastContainer>
  );
};
