import { SubscriptionRow } from "src/components/subscriptionList/subscriptionList.tsx";
import { SubscriptionSortBy } from "src/model/subscription.ts";

export interface SubscriptionColumn {
  key: keyof Omit<SubscriptionRow, "containers">;
  value: string;
  removable: boolean;
  sortKey?: SubscriptionSortBy;
  additionalStyle?: Record<string, string>;
}

export const subscriptionColumns: SubscriptionColumn[] = [
  {
    key: "shippingLine",
    removable: false,
    value: "Shipping Line",
  },
  {
    key: "billOfLading",
    removable: true,
    value: "MBL Number",
    sortKey: "BillOfLading",
  },
  {
    key: "bookingNumber",
    removable: true,
    value: "Booking Number",
    sortKey: "BookingNumber",
  },
  {
    key: "etdPortOfLoading",
    removable: true,
    value: "ETD Port of Loading",
    sortKey: "EtdPortOfLoading",
  },
  {
    key: "portOfLoading",
    removable: true,
    value: "Port of Loading",
    sortKey: "PortOfLoading",
  },
  {
    key: "etaPortOfDischarge",
    removable: true,
    value: "ETA Port of Discharge",
    sortKey: "EtaPortOfDischarge",
  },
  {
    key: "portOfDischarge",
    removable: true,
    value: "Port of Discharge",
    sortKey: "PortOfDischarge",
  },
  {
    key: "externalReference",
    removable: true,
    value: "External Reference",
  },
  {
    key: "shippersReference",
    removable: true,
    value: "Shippers Reference",
  },
  {
    key: "daysOfDelay",
    removable: true,
    value: "Days of Delay",
  },
];
