import { FC, ReactNode, useMemo } from "react";
import { Table } from "react-bootstrap";

import { containerColumns } from "src/components/containerList/containerListColumns.tsx";
import { usePaginationContext } from "src/components/containerList/containerListPaginationContext.ts";
import { CopyableText } from "src/components/copyableText.tsx";
import { HeaderColumn } from "src/components/table/headerColumn.tsx";
import { ContainerSortBy } from "src/model/container.ts";
import { Container } from "src/model/subscription.ts";
import { buildPortString } from "src/utils/ports.ts";
import { formatDateTime } from "src/utils/timeFormat.ts";

interface ContainerListProps {
  containers: Container[];
  columns: (keyof ContainerRow)[];
}

export interface ContainerRow {
  containerNumber: ReactNode;
  detentionDemurragePrecarriageDays?: string;
  detentionDemurrageOncarriageDays?: string;
  lastStatus: string | null;
  lastStatusLocation: string | null;
  lastStatusDate: string;
  billOfLading: ReactNode;
  bookingNumber: ReactNode;
  externalReference: ReactNode;
  shippersReference: ReactNode;
  portOfLoading: string | null;
  etdPortOfLoading: string | null;
  portOfDischarge: string | null;
  etaPortOfDischarge: string | null;
  shippingLine: string;
  daysOfDelay: number | null;
}

export const ContainerList: FC<ContainerListProps> = ({ containers, columns }) => {
  const { pagination, setPagination } = usePaginationContext();

  const containerRows: ContainerRow[] = useMemo(() => {
    return containers.map(
      (c): ContainerRow => ({
        containerNumber: <CopyableText text={c.containerNumber} />,
        lastStatus: c.lastStatus?.status ?? null,
        lastStatusLocation: c.lastStatus?.location ?? null,
        lastStatusDate: formatDateTime(c.lastStatus?.time),
        detentionDemurragePrecarriageDays: c.detentionDemurragePrecarriageDays?.toString(),
        detentionDemurrageOncarriageDays: c.detentionDemurrageOncarriageDays?.toString(),
        billOfLading: <CopyableText text={c.billOfLading} />,
        bookingNumber: <CopyableText text={c.bookingNumber} />,
        portOfLoading: buildPortString(c.portOfLoading, c.portOfLoadingCode),
        portOfDischarge: buildPortString(c.portOfDischarge, c.portOfDischargeCode),
        shippersReference: <CopyableText text={c.shippersReference} />,
        externalReference: <CopyableText text={c.externalReference} />,
        etaPortOfDischarge: formatDateTime(c.etaPortOfDischarge),
        etdPortOfLoading: formatDateTime(c.etdPortOfLoading),
        shippingLine: `${c.shippingLineName} (${c.scac})`,
        daysOfDelay: c.daysOfDelay,
      })
    );
  }, [containers]);

  const onSortChange = (sortKey: ContainerSortBy | undefined) => {
    if (sortKey === undefined) {
      return;
    }

    if (pagination.sortBy === sortKey) {
      setPagination((prevState) => ({
        ...prevState,
        pagination: { ...prevState.pagination, sortDir: prevState.pagination.sortDir === "Asc" ? "Desc" : "Asc" },
      }));
    } else {
      setPagination((prevState) => ({
        ...prevState,
        pagination: { ...prevState.pagination, sortBy: sortKey, sortDir: "Asc" },
      }));
    }
  };

  if (containers.length === 0) {
    return <div>No containers found.</div>;
  }

  const orderedAndFilteredColumns = containerColumns
    .filter((cc) => columns.includes(cc.key))
    .toSorted((a, b) => columns.indexOf(a.key) - columns.indexOf(b.key));

  return (
    <Table>
      <thead>
        <tr>
          {orderedAndFilteredColumns
            .filter((cc) => columns.indexOf(cc.key) >= 0)
            .map((c) => (
              <th key={`th-${c.key}`}>
                <HeaderColumn
                  value={c.value}
                  sortKey={c.sortKey}
                  onSortChange={onSortChange}
                  selectedSortKey={pagination.sortBy}
                  selectedSortDir={pagination.sortDir}
                />
              </th>
            ))}
        </tr>
      </thead>
      <tbody className="table-group-divider">
        {containerRows.map((cr, index) => (
          <tr key={`tr-${index}`}>
            {orderedAndFilteredColumns
              .filter((cc) => columns.indexOf(cc.key) >= 0)
              .map((column) => (
                <td key={`td-${column.key}`}>{cr[column.key]}</td>
              ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
