import { ArrowDown, ArrowDownUp, ArrowUp } from "react-bootstrap-icons";

import { SortDir } from "src/model/pageRequest.ts";

interface HeaderColumnProps<T> {
  value: string;
  sortKey: T;
  onSortChange: (sortKey: T) => void;
  selectedSortKey: T;
  selectedSortDir: SortDir;
}

export const HeaderColumn = <T,>({
  value,
  sortKey,
  onSortChange,
  selectedSortKey,
  selectedSortDir,
}: HeaderColumnProps<T>) => {
  const buildSortArrow = (sortKey?: T) => {
    if (sortKey === undefined) {
      return null;
    }
    if (sortKey === selectedSortKey) {
      switch (selectedSortDir) {
        case "Asc":
          return <ArrowUp size={14} className="ms-1" />;
        case "Desc":
          return <ArrowDown size={14} className="ms-1 " />;
      }
    }
    return <ArrowDownUp size={14} className="ms-2 text-secondary" />;
  };

  return (
    <span onClick={() => onSortChange(sortKey)} style={{ cursor: sortKey !== undefined ? "pointer" : undefined }}>
      {value}
      {buildSortArrow(sortKey)}
    </span>
  );
};
