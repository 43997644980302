import { FC } from "react";
import { Table } from "react-bootstrap";

import { LogEntry } from "src/model/subscriptionLog.ts";
import { formatDateTime } from "src/utils/timeFormat.ts";

interface SubscriptionLogEventListProps {
  subscriptionLogEvents: LogEntry[];
}

export const SubscriptionLogEventList: FC<SubscriptionLogEventListProps> = ({ subscriptionLogEvents }) => {
  return (
    <Table className="table-secondary m-0">
      <thead>
        <tr>
          <th>Log Date</th>
          <th>Log Message</th>
        </tr>
      </thead>
      <tbody className="table-group-divider">
        {subscriptionLogEvents.map((sle, index) => (
          <tr key={`subscription-log-event-${index}`}>
            <td>{formatDateTime(sle.timestamp)}</td>
            <td>{sle.message}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
