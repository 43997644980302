declare const apiUrl: string;
declare const isea3Url: string;
declare const sentryEnv: string;
declare const release: string;

interface Config {
  apiUrl: string;
  isea3Url: string;
  sentryEnv: string;
  release: string;
}

let config: Config | null = null;

export const getConfig: () => Config = () => {
  if (config === null) {
    config = {
      apiUrl: apiUrl,
      isea3Url: isea3Url,
      sentryEnv: sentryEnv,
      release: release,
    };
  }

  return config;
};
