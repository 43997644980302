import { ContainerRow } from "src/components/containerList/containerList.tsx";
import { ContainerSortBy } from "src/model/container.ts";

export interface ContainerColumn {
  key: keyof ContainerRow;
  value: string;
  removable: boolean;
  sortKey?: ContainerSortBy;
}

export const containerColumns: ContainerColumn[] = [
  {
    key: "containerNumber",
    removable: false,
    value: "Container-No.",
  },
  {
    key: "lastStatus",
    removable: true,
    value: "Last Status",
  },
  {
    key: "lastStatusLocation",
    removable: true,
    value: "Last Status Place",
  },
  {
    key: "lastStatusDate",
    removable: true,
    value: "Last Status Date",
  },
  {
    key: "etdPortOfLoading",
    removable: true,
    value: "ETD Port of Loading",
    sortKey: "EtdPortOfLoading",
  },
  {
    key: "portOfLoading",
    removable: true,
    value: "Port of Loading",
    sortKey: "PortOfLoading",
  },
  {
    key: "etaPortOfDischarge",
    removable: true,
    value: "ETA Port of Discharge",
    sortKey: "EtaPortOfDischarge",
  },
  {
    key: "portOfDischarge",
    removable: true,
    value: "Port of Discharge",
    sortKey: "PortOfDischarge",
  },
  {
    key: "shippingLine",
    removable: true,
    value: "Shipping Line",
  },
  {
    key: "billOfLading",
    removable: true,
    value: "MBL Number",
    sortKey: "BillOfLading",
  },
  {
    key: "bookingNumber",
    removable: true,
    value: "Booking Number",
    sortKey: "BookingNumber",
  },
  {
    key: "externalReference",
    removable: true,
    value: "External Reference",
  },
  {
    key: "shippersReference",
    removable: true,
    value: "Shippers Reference",
  },
  {
    key: "daysOfDelay",
    removable: true,
    value: "Days of Delay",
  },
  {
    key: "detentionDemurragePrecarriageDays",
    removable: true,
    value: "Det./Dem. Pre Carriage",
  },
  {
    key: "detentionDemurrageOncarriageDays",
    removable: true,
    value: "Det./Dem. On Carriage",
  },
];
