import * as Sentry from "@sentry/react";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";

import App from "src/app.tsx";
import { DialogContainer } from "src/components/dialogContainer.tsx";
import { ToastMessageContainer } from "src/components/toastMessageContainer.tsx";
import { getConfig } from "src/config.ts";
import { store } from "src/store/store.ts";

import "./index.scss";
import "leaflet/dist/leaflet.css";

Sentry.init({
  enabled: getConfig().sentryEnv !== "local",
  dsn: "https://20ae34c1ef716bc59756903d872e7db8@o4507148220694528.ingest.de.sentry.io/4507148229148752",
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: getConfig().sentryEnv,
  release: getConfig().release,
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <App />
        <ToastMessageContainer />
        <DialogContainer />
      </Provider>
    </HelmetProvider>
  </StrictMode>
);
