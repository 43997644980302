export interface Customer {
  id: string;
  active: boolean;
  externalCustomerReference: string | null;
  companyName: string;
  subscriptionInactiveAfterContainerReturnedInDays: number;
  subscriptionRemovedAfterDoneInDays: number;
  trackingConsumer: TrackingConsumerType | null;
  containerReportExcelTemplate: string | null;
  containerReportMail: string | null;
  containerReportMailingsPerDay: number | null;
  containerReportAutomaticSending: boolean | null;
}

export enum TrackingConsumer {
  SETLOG = "SETLOG",
}

type TrackingConsumerType = keyof typeof TrackingConsumer;

export const mapTracingConsumerToReadableString = (trackingConsumer: TrackingConsumerType | null): string => {
  if (trackingConsumer === null) {
    return "None";
  }

  switch (TrackingConsumer[trackingConsumer]) {
    case TrackingConsumer.SETLOG:
      return "Setlog";
  }
};

export type CustomerSortBy = "Name";

export interface CustomerSaveRequest {
  active: boolean;
  externalCompanyReference: string | null;
  companyName: string;
  trackingConsumer: TrackingConsumerType | null;
  subscriptionInactiveAfterContainerReturnedInDays: number;
  subscriptionRemovedAfterDoneInDays: number;
}

export interface CustomerFilterProps {
  customerId?: string;
  companyId?: string;
}
