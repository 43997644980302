import { FC } from "react";

import { CustomerDropdown } from "src/components/customerDropdown.tsx";

interface CustomerFilterProps {
  onChangeCustomerId: (customerId: string | undefined) => void;
  selectedCustomerId?: string;
}

export const CustomerFilter: FC<CustomerFilterProps> = ({ onChangeCustomerId, selectedCustomerId }) => {
  return (
    <>
      <h5 className="d-flex justify-content-between align-items-center">Customer</h5>
      <CustomerDropdown
        allowDeselect={true}
        customerId={selectedCustomerId}
        onChangeCustomerId={onChangeCustomerId}
        size={"sm"}
      />
    </>
  );
};
