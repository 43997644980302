import { FC } from "react";
import { Helmet } from "react-helmet-async";

export const JobListPage: FC = () => {
  return (
    <>
      <Helmet>
        <title>iSEA Trace - Jobs</title>
      </Helmet>
      <div>Jobs</div>
    </>
  );
};
