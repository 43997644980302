import { FC, Fragment, MouseEvent, useEffect, useMemo, useState } from "react";
import { Collapse, Fade, Spinner, Table } from "react-bootstrap";
import { CaretDownFill, CaretUpFill, Check, Pencil, PlusCircle, X } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

import { fetchCustomers } from "src/api/customers.ts";
import { CompanyList } from "src/components/companyList.tsx";
import { Pagination } from "src/components/pagination.tsx";
import { Customer, CustomerSortBy, mapTracingConsumerToReadableString } from "src/model/customer.ts";
import { PageAndSortRequest } from "src/model/pageRequest.ts";
import { PaginatedResponse } from "src/model/paginatedResponse.ts";
import { parseSearchParamToNumber, useSearchParams } from "src/utils/searchParams.ts";

import styles from "./customerList.module.scss";

const defaultPagination: PageAndSortRequest<CustomerSortBy> = {
  limit: 10,
  offset: 0,
  sortBy: "Name",
  sortDir: "Asc",
};

export const CustomerList: FC = () => {
  const [customers, setCustomers] = useState<PaginatedResponse<Customer> | undefined>();
  const [openCustomer, setOpenCustomer] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const offset = parseSearchParamToNumber(searchParams.get("offset")) ?? defaultPagination.offset;

  useEffect(() => {
    setOpenCustomer(null);
  }, [customers]);

  const setOffset = useMemo(
    () => (offset: number) => {
      setSearchParams((prev) => {
        Object.entries(defaultPagination).forEach(([key, value]) => {
          prev.set(key, value);
        });
        prev.set("offset", offset.toString());
        return prev;
      });
    },
    [setSearchParams]
  );

  useEffect(() => {
    setSearchParams(
      (prev) => {
        if (prev.size === 0) {
          Object.entries(defaultPagination).forEach(([key, value]) => {
            prev.set(key, value);
          });
        }
        return prev;
      },
      { replace: true }
    );
  }, [setSearchParams]);

  useEffect(() => {
    setLoading(true);
    fetchCustomers({ ...defaultPagination, offset: offset })
      .then((result) => setCustomers(result))
      .finally(() => setLoading(false));
  }, [offset]);

  const toggleCustomerState = (event: MouseEvent<HTMLTableRowElement>, customerId: string) => {
    if (!(event.target instanceof HTMLAnchorElement) && !document.getSelection()?.toString())
      setOpenCustomer((prevState) => {
        if (prevState === customerId) {
          return null;
        } else {
          return customerId;
        }
      });
  };

  const onPageChange = (offset: number) => {
    setOffset(offset);
  };

  if (customers === undefined) {
    return (
      <div className={`d-flex justify-content-center align-items-center ${styles.block}`}>
        <div className={`w-100 h-100 ${styles.fade}`}></div>
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <Fade in={loading} unmountOnExit={true} mountOnEnter={true}>
        <div className={`d-flex justify-content-center align-items-center ${styles.block}`}>
          <div className={`w-100 h-100 ${styles.fade}`}></div>
          <Spinner />
        </div>
      </Fade>
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Active</th>
            <th>External Customer Reference</th>
            <th>Subscription Inactive After Container Returned In Days</th>
            <th>Subscription Removed After Done In Days</th>
            <th>Tracing Consumer</th>
            <th style={{ width: "34px" }}>
              <Link to="/customers/new" className="text-black">
                <PlusCircle size={18} />
              </Link>
            </th>
            <th style={{ width: "34px" }} />
          </tr>
        </thead>
        <tbody>
          {customers.items.map((c) => (
            <Fragment key={`customer-${c.id}`}>
              <tr onClick={(event) => toggleCustomerState(event, c.id)}>
                <td>{c.companyName}</td>
                <td>{c.active ? <Check size={24} /> : <X size={24} />}</td>
                <td>{c.externalCustomerReference}</td>
                <td>{c.subscriptionInactiveAfterContainerReturnedInDays}</td>
                <td>{c.subscriptionRemovedAfterDoneInDays}</td>
                <td>{mapTracingConsumerToReadableString(c.trackingConsumer)}</td>
                <td>
                  <Link to={`/customers/${c.id}`} className="text-black">
                    <Pencil size={18} />
                  </Link>
                </td>
                <td>{openCustomer === c.id ? <CaretUpFill role="button" /> : <CaretDownFill role="button" />}</td>
              </tr>
              <tr>
                <td colSpan={8} className="p-0 border-bottom-0">
                  <Collapse in={openCustomer === c.id} mountOnEnter={true} unmountOnExit={false}>
                    <div className="border-bottom">
                      <div className="m-3">
                        <CompanyList customerId={c.id} />
                      </div>
                    </div>
                  </Collapse>
                </td>
              </tr>
            </Fragment>
          ))}
        </tbody>
      </Table>
      <Pagination
        totalItems={customers.totalItems}
        offset={offset}
        limit={defaultPagination.limit}
        onPageChange={onPageChange}
      />
    </>
  );
};
