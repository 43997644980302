import { FC, useEffect, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import { Check, Pencil, PlusCircle, X } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

import { fetchCompanies } from "src/api/customers.ts";
import { Customer, mapTracingConsumerToReadableString } from "src/model/customer.ts";

interface CompanyListProps {
  customerId: string;
}

export const CompanyList: FC<CompanyListProps> = ({ customerId }) => {
  const [companies, setCompanies] = useState<Customer[] | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    fetchCompanies(customerId)
      .then((result) => setCompanies(result))
      .finally(() => setLoading(false));
  }, [customerId]);

  if (loading || companies === null) {
    return (
      <div className={`d-flex justify-content-center align-items-center`}>
        <Spinner />
      </div>
    );
  }

  if (companies.length === 0) {
    return (
      <div className="bg-secondary-subtle m-0 p-2">
        No companies found for this customer. Click{" "}
        <Link to={`/customers/${customerId}/companies/new`} className="text-black">
          here
        </Link>{" "}
        to create a new company.
      </div>
    );
  }

  return (
    <Table className="table-secondary m-0">
      <thead>
        <tr>
          <th>Name</th>
          <th>Active</th>
          <th>External Customer Reference</th>
          <th>Subscription Inactive After Container Returned In Days</th>
          <th>Subscription Removed After Done In Days</th>
          <th>Tracing Consumer</th>
          <th style={{ width: "34px" }}>
            <Link to={`/customers/${customerId}/companies/new`} className="text-black">
              <PlusCircle size={18} />
            </Link>
          </th>
        </tr>
      </thead>
      <tbody>
        {companies.map((c) => (
          <tr key={`company-${c.id}`}>
            <td>{c.companyName}</td>
            <td>{c.active ? <Check size={24} /> : <X size={24} />}</td>
            <td>{c.externalCustomerReference}</td>
            <td>{c.subscriptionInactiveAfterContainerReturnedInDays}</td>
            <td>{c.subscriptionRemovedAfterDoneInDays}</td>
            <td>{mapTracingConsumerToReadableString(c.trackingConsumer)}</td>
            <td>
              <Link to={`/customers/${customerId}/companies/${c.id}`} className="text-black">
                <Pencil size={18} />
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
