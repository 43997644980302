import { Provider, createContext, useContext } from "react";

interface State<P, F> {
  pagination: P;
  filter: F;
}

export interface PaginationContextProps<P, F> extends State<P, F> {
  setPagination: (value: (prevState: State<P, F>) => State<P, F>) => void;
}

export const buildPaginationContext = <P, F>(): {
  Provider: Provider<PaginationContextProps<P, F> | null>;
  usePaginationContext: () => PaginationContextProps<P, F>;
} => {
  const PaginationContext = createContext<PaginationContextProps<P, F> | null>(null);

  const usePaginationContext = (): PaginationContextProps<P, F> => {
    const context = useContext(PaginationContext);
    if (context === null) {
      throw new Error("usePaginationContext must be within PaginationProvider");
    }
    return context;
  };

  return {
    Provider: PaginationContext.Provider,
    usePaginationContext: usePaginationContext,
  };
};
