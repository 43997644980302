import { FC, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

import { useDialog } from "src/store/store.ts";

export const DialogContainer: FC = () => {
  const { dialog, hideDialog } = useDialog();
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    if (dialog !== null) {
      setShow(true);
    }
  }, [dialog]);

  const onClose = () => {
    setShow(false);
  };

  const onExited = () => {
    hideDialog();
  };

  const buildModalContent = () => {
    if (dialog === null) {
      return null;
    }

    return (
      <>
        <Modal.Header closeButton={true}>
          <Modal.Title>{dialog.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{dialog.message}</Modal.Body>
        <Modal.Footer>
          <Button onClick={onClose}>Close</Button>
        </Modal.Footer>
      </>
    );
  };

  return (
    <Modal centered={true} show={show} onExited={onExited} onHide={onClose}>
      {buildModalContent()}
    </Modal>
  );
};
