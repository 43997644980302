import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { isEqual } from "lodash";

import { Auth, fetchOwnUserDetails, refreshToken, updatePreferences } from "src/api/user.ts";
import { User, UserPreferences, UserWithPreferences } from "src/model/user.ts";
import { RootState, useAppDispatch } from "src/store/store.ts";
import {
  loadContainerColumnOrderSetting,
  loadContainerColumnSetting,
  loadContainerPageSize,
  loadSubscriptionColumnOrderSetting,
  loadSubscriptionColumnSetting,
  loadSubscriptionPageSize,
} from "src/utils/localStorage.ts";

export type UserRole = "User" | "Admin" | "Service";

export const UserRights = ["ViewSubscriptionReport", "ViewContainerReport"] as const;

export type UserRight = (typeof UserRights)[number];

interface AccessToken {
  Name: string;
  Role: UserRole;
  UserId: string;
  CustomerId?: string;
  CompanyId?: string;
  exp: number;
}

export interface UserState {
  username: string;
  role: UserRole;
  rights: UserRight[];
  customerId: string | null;
  companyId: string | null;
}

export interface AuthState {
  accessToken: string;
  expiry: number;
  refreshToken: string;
  userId: number;
}

interface AuthReducerState {
  auth: AuthState | null | undefined;
  user: UserState | null | undefined;
  preferences: UserPreferences | null | undefined;
}

const initialState: AuthReducerState = {
  auth: undefined,
  user: undefined,
  preferences: undefined,
};

export const parseAccessToken = (accessTokenString: string): AccessToken => {
  const accessTokenParts = accessTokenString.split(".");
  if (accessTokenParts[1] !== undefined) {
    return JSON.parse(atob(accessTokenParts[1])) as AccessToken;
  }
  throw new Error("Unable to parse access token of user");
};

const mapAuth = (user: Auth): AuthState => {
  const accessToken = parseAccessToken(user.accessToken);

  return {
    accessToken: user.accessToken,
    refreshToken: user.refreshToken,
    userId: parseInt(accessToken.UserId),
    expiry: accessToken.exp,
  };
};

const mapUserToState = (user: User): UserState => {
  return {
    role: user.userRole,
    rights: user.userRights,
    username: user.userName,
    customerId: user.customerId,
    companyId: user.companyId,
  };
};

const isTokenExpired = (expiry: number): boolean => {
  return expiry * 1000 < Date.now() - 60000;
};

export const refreshAuth = async (dispatch: ReturnType<typeof useAppDispatch>, getState: () => RootState) => {
  const state = getState();
  const authState = state.auth.auth;
  if (authState === null || authState === undefined) {
    return;
  }
  if (isTokenExpired(authState.expiry)) {
    try {
      const auth = await refreshToken(authState.refreshToken);
      dispatch(setAuth(auth));
      const details = await fetchOwnUserDetails();
      dispatch(setUser(details));
    } catch (error) {
      console.error("Unable to refresh accessToken", error);
    }
  }
};

const migrateSubscriptionListPreferences = async (user: UserWithPreferences) => {
  const subscriptionColumnSetting = loadSubscriptionColumnSetting();
  const subscriptionColumnOrderSetting = loadSubscriptionColumnOrderSetting();
  const subscriptionPageSize = loadSubscriptionPageSize();

  const containerColumnSetting = loadContainerColumnSetting();
  const containerColumnOrderSetting = loadContainerColumnOrderSetting();
  const containerPageSize = loadContainerPageSize();

  if (
    user.preferences === null &&
    (subscriptionColumnSetting !== null ||
      subscriptionColumnOrderSetting !== null ||
      subscriptionPageSize !== null ||
      containerColumnSetting !== null ||
      containerColumnOrderSetting !== null ||
      containerPageSize !== null)
  ) {
    const preferences: UserPreferences = {
      subscriptionColumnSetting: subscriptionColumnSetting ?? undefined,
      subscriptionColumnOrderSetting: subscriptionColumnOrderSetting ?? undefined,
      subscriptionPageSize: subscriptionPageSize ?? undefined,

      containerColumnSetting: containerColumnSetting ?? undefined,
      containerColumnOrderSetting: containerColumnOrderSetting ?? undefined,
      containerPageSize: containerPageSize ?? undefined,
    };
    await updatePreferences(preferences);
    user.preferences = preferences;
  }

  localStorage.removeItem("containerColumnSetting");
  localStorage.removeItem("containerColumnOrderSetting");
  localStorage.removeItem("containerPageSize");
  localStorage.removeItem("subscriptionColumnSetting");
  localStorage.removeItem("subscriptionColumnOrderSetting");
  localStorage.removeItem("subscriptionPageSize");
};

const migrateAuthState = async (dispatch: ReturnType<typeof useAppDispatch>, outdatedAuthString: string) => {
  localStorage.removeItem("authentication");
  const outdatedAuthState = JSON.parse(outdatedAuthString) as Auth;
  try {
    const auth = await refreshToken(outdatedAuthState.refreshToken);
    dispatch(setAuth(auth));
    const user = await fetchOwnUserDetails();
    await migrateSubscriptionListPreferences(user);
    dispatch(setUser(user));
  } catch (error) {
    console.error(error);
    dispatch(clearUser());
  }
};

const isAuthReducerState = (state: unknown): state is AuthReducerState => {
  return !!(typeof state === "object" && (state as AuthReducerState).auth);
};

const isAuthState = (state: unknown): state is AuthState => {
  return !!(typeof state === "object" && (state as AuthState).accessToken);
};

export const initialize = async (dispatch: ReturnType<typeof useAppDispatch>) => {
  const outdatedAuthString = localStorage.getItem("authentication");
  if (outdatedAuthString !== null) {
    await migrateAuthState(dispatch, outdatedAuthString);
    return;
  }

  const authString = localStorage.getItem("auth");

  if (authString === null) {
    dispatch(clearUser());
    return;
  }

  let auth: AuthState | null | undefined;
  const authState = JSON.parse(authString) as unknown;
  if (isAuthReducerState(authState)) {
    // Migration from previous version
    auth = authState.auth;
  } else if (isAuthState(authState)) {
    auth = authState;
  } else {
    dispatch(clearUser());
    return;
  }

  if (auth === null || auth === undefined) {
    dispatch(clearUser());
    return;
  }

  if (isTokenExpired(auth.expiry)) {
    try {
      const refreshedAuth = await refreshToken(auth.refreshToken);
      dispatch(setAuth(refreshedAuth));
    } catch (error) {
      console.error(error);
      dispatch(clearUser());
      return;
    }
  } else {
    dispatch(setAuth(auth));
  }

  const user = await fetchOwnUserDetails();
  await migrateSubscriptionListPreferences(user);
  dispatch(setUser(user));
  dispatch(setPreferences(user.preferences));
};

export const updateUserPreferences = (preferences: UserPreferences) => {
  return (dispatch: ReturnType<typeof useAppDispatch>, getState: () => RootState) => {
    const state = getState();
    const userState = state.auth.preferences;
    if (userState !== undefined && userState !== null) {
      const newPreferences: UserPreferences = {
        ...userState,
        ...preferences,
      };
      if (!isEqual(userState, newPreferences)) {
        updatePreferences(newPreferences).catch((error) => console.error(error));
        dispatch(setPreferences(newPreferences));
      }
    }
  };
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<Auth>) => {
      const newState = { ...state, auth: mapAuth(action.payload) };
      localStorage.setItem("auth", JSON.stringify(newState.auth));

      return newState;
    },
    setUser: (state, action: PayloadAction<User>) => {
      return { ...state, user: mapUserToState(action.payload) };
    },
    setPreferences: (state, action: PayloadAction<UserPreferences | null>) => {
      return { ...state, preferences: action.payload };
    },
    clearUser: () => {
      localStorage.removeItem("auth");
      return { user: null, auth: null, preferences: null };
    },
  },
});

export const authReducer = authSlice.reducer;
export const setUser = authSlice.actions.setUser;
export const setPreferences = authSlice.actions.setPreferences;
export const setAuth = authSlice.actions.setAuth;
export const clearUser = authSlice.actions.clearUser;
