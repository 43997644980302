import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface ToastMessage {
  show: boolean;
  message: string;
  id: string;
}

export interface ToastMessageState {
  toastMessages: ToastMessage[];
}

const initialState: ToastMessageState = {
  toastMessages: [],
};

export const toastMessageSlice = createSlice({
  name: "toastMessage",
  initialState,
  reducers: {
    addToast: (state, action: PayloadAction<{ message: string; id: string }>) => {
      return {
        toastMessages: [
          ...state.toastMessages,
          { show: false, message: action.payload.message, id: action.payload.id },
        ],
      };
    },
    showToast: (state, action: PayloadAction<string>) => {
      return {
        toastMessages: state.toastMessages.map((t) => ({ ...t, show: t.id === action.payload ? true : t.show })),
      };
    },
    hideToast: (state, action: PayloadAction<string>) => {
      return {
        toastMessages: state.toastMessages.map((t) => ({ ...t, show: t.id === action.payload ? false : t.show })),
      };
    },
    removeToast: (state, action: PayloadAction<string>) => {
      return { toastMessages: state.toastMessages.filter((t) => t.id !== action.payload) };
    },
  },
});

export const toastReducer = toastMessageSlice.reducer;
export const addToast = toastMessageSlice.actions.addToast;
export const removeToast = toastMessageSlice.actions.removeToast;
export const showToast = toastMessageSlice.actions.showToast;
export const hideToast = toastMessageSlice.actions.hideToast;
