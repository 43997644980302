import { FC } from "react";
import { Form } from "react-bootstrap";

import styles from "./pagination.module.scss";

export interface PaginationProps {
  limit: number;
  offset: number;
  totalItems: number;
  onPageChange: (offset: number) => void;
  onPageSizeChange?: (limit: number) => void;
  pageSizes?: number[];
}

interface PaginationItemProps {
  page: number;
  active: boolean;
  onClick: () => void;
}

const PaginationItem: FC<PaginationItemProps> = ({ onClick, page, active }) => {
  if (active) {
    return (
      <li className="page-item active">
        <span className="page-link">{page}</span>
      </li>
    );
  } else {
    return (
      <li className="page-item">
        <span className="page-link" role="button" onClick={onClick}>
          {page}
        </span>
      </li>
    );
  }
};

export const Pagination: FC<PaginationProps> = ({
  limit,
  offset,
  totalItems,
  onPageChange,
  onPageSizeChange,
  pageSizes = [10, 20, 50],
}) => {
  const pagination = (
    <ul className="pagination justify-content-center">
      {[...Array(Math.ceil(totalItems / limit)).keys()].map((number) => (
        <PaginationItem
          key={`pagination-item-${number}`}
          onClick={() => onPageChange(number * limit)}
          page={number + 1}
          active={offset === number * limit}
        />
      ))}
    </ul>
  );

  if (onPageSizeChange === undefined) {
    return pagination;
  }

  return (
    <div className={styles.wrapper}>
      {pagination}
      <Form.Select
        className={styles.pageSizeDropdown}
        value={limit}
        onChange={(event) => onPageSizeChange(parseInt(event.target.value))}
      >
        {pageSizes.map((ps, index) => (
          <option key={`page-size-option-${index}`}>{ps}</option>
        ))}
      </Form.Select>
    </div>
  );
};
