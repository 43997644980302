import { FC } from "react";
import { Helmet } from "react-helmet-async";

import { CustomerList } from "src/components/customerList.tsx";

export const CustomerListPage: FC = () => {
  return (
    <>
      <Helmet>
        <title>iSEA Trace - Customers</title>
      </Helmet>
      <CustomerList />
    </>
  );
};
