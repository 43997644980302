import createClient, { Middleware } from "openapi-fetch";

import { paths } from "src/api/schema";
import { getConfig } from "src/config.ts";
import { RootState } from "src/store/store.ts";

let client: ReturnType<typeof createClient<paths>> | null = null;
let getStateFunction: (() => RootState) | null = null;

export const setGetStateFunction = (getState: () => RootState) => {
  getStateFunction = getState;
};

const setupClient = (): ReturnType<typeof createClient<paths>> => {
  const c = createClient<paths>({ baseUrl: getConfig().apiUrl });
  const authMiddleware: Middleware = {
    async onRequest({ request }) {
      if (getStateFunction !== null) {
        const auth = getStateFunction().auth.auth;
        if (auth) {
          request.headers.set("Authorization", "Bearer " + auth.accessToken);
        }
      }
      return request;
    },
  };
  c.use(authMiddleware);
  return c;
};

export const getClient = (): ReturnType<typeof createClient<paths>> => {
  if (client === null) {
    client = setupClient();
  }

  return client;
};
