import { FC } from "react";
import { Dot } from "react-bootstrap-icons";
import DatePicker from "react-datepicker";

import { formatDate } from "src/utils/timeFormat.ts";

import styles from "./rangeFilter.module.scss";
import "react-datepicker/dist/react-datepicker.css";

export interface RangeFilterQuickOption {
  label: string;
  range: [Date, undefined] | [Date, Date] | [undefined, Date];
}

interface RangeFilterProps {
  title: string;
  onChangeDate: (range: [Date?, Date?]) => void;
  selectedDate?: [Date?, Date?];
  quickOptions?: RangeFilterQuickOption[];
}

const dateToUtc = (date: Date | null): Date | undefined => {
  if (date === null) {
    return undefined;
  }

  return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
};

const buildRangeTitle = (range: RangeFilterQuickOption["range"]): string => {
  if (range[0] !== undefined && range[1] === undefined) {
    return `${formatDate(range[0])} or later`;
  }
  if (range[0] === undefined && range[1] !== undefined) {
    return `until ${formatDate(range[1])}`;
  }
  return `${formatDate(range[0])} - ${formatDate(range[1])}`;
};

export const RangeFilter: FC<RangeFilterProps> = ({ title, onChangeDate, selectedDate, quickOptions }) => {
  return (
    <>
      <h5 className="d-flex justify-content-between align-items-center">{title}</h5>
      <div className="m-2">
        {quickOptions?.map(({ label, range }, index) => (
          <div key={`quick-option-${index}`}>
            <div
              onClick={() => onChangeDate(range)}
              style={{ cursor: "pointer" }}
              title={buildRangeTitle(range)}
              className="d-inline-flex align-items-center"
            >
              <Dot /> {label}
            </div>
          </div>
        ))}
      </div>
      <div className={`${styles.rangeInputRow} mb-1`}>
        <div>From</div>
        <DatePicker
          selected={selectedDate?.at(0)}
          onChange={(date) => onChangeDate([dateToUtc(date), selectedDate?.at(1)])}
          calendarStartDay={1}
          isClearable={true}
          maxDate={selectedDate?.at(1)}
          className={`${styles.rangeInput} form-control form-control-sm`}
          strictParsing={true}
          placeholderText="dd.mm.yyyy"
          dateFormat="dd.MM.yyyy"
        />
      </div>
      <div className={`${styles.rangeInputRow} mb-1`}>
        <div>To</div>
        <DatePicker
          selected={selectedDate?.at(1)}
          onChange={(date) => onChangeDate([selectedDate?.at(0), dateToUtc(date)])}
          calendarStartDay={1}
          isClearable={true}
          minDate={selectedDate?.at(0)}
          className={`${styles.rangeInput} form-control form-control-sm`}
          strictParsing={true}
          placeholderText="dd.mm.yyyy"
          dateFormat="dd.MM.yyyy"
        />
      </div>
    </>
  );
};
