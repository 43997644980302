import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { FC } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { GripVertical } from "react-bootstrap-icons";

interface ColumnDropdownItemProps {
  id: string;
  checked: boolean;
  toggleColumn: () => void;
  value: string;
}

export const ColumnDropdownItem: FC<ColumnDropdownItemProps> = ({ id, checked, toggleColumn, value }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Dropdown.ItemText
      ref={setNodeRef}
      style={style}
      {...attributes}
      className="d-flex justify-content-between align-items-center"
    >
      <Form.Check type="checkbox" id={`column-${id}`} className="fs-6">
        <Form.Check.Input
          type="checkbox"
          checked={checked}
          onChange={toggleColumn}
          // disabled={!c.removable}
        />
        <Form.Check.Label className="d-block text-nowrap">{value}</Form.Check.Label>
      </Form.Check>
      <GripVertical className="ms-2" {...listeners} />
    </Dropdown.ItemText>
  );
};
