import { FC } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

import { Customer, CustomerSaveRequest, TrackingConsumer } from "src/model/customer.ts";
import { nullIfEmpty } from "src/utils/strings.ts";

interface CustomerFormProps {
  customer: Customer | null;
  saveCustomer: (customerSaveRequest: CustomerSaveRequest) => Promise<void>;
}

interface CustomerFormData {
  active: boolean;
  externalCompanyReference?: string;
  companyName: string;
  subscriptionInactiveAfterContainerReturnedInDays: number;
  subscriptionRemovedAfterDoneInDays: number;
  trackingConsumer: keyof typeof TrackingConsumer | "NONE";
}

const buildFormDefaultValues = (customer: Customer | null): Partial<CustomerFormData> => {
  if (customer === null) {
    return {
      subscriptionInactiveAfterContainerReturnedInDays: 30,
      subscriptionRemovedAfterDoneInDays: 90,
      active: true,
    };
  }
  return {
    active: customer.active,
    companyName: customer.companyName,
    externalCompanyReference: customer.externalCustomerReference ?? undefined,
    subscriptionInactiveAfterContainerReturnedInDays: customer.subscriptionInactiveAfterContainerReturnedInDays,
    subscriptionRemovedAfterDoneInDays: customer.subscriptionRemovedAfterDoneInDays,
    trackingConsumer: customer.trackingConsumer === null ? "NONE" : customer.trackingConsumer,
  };
};

export const CustomerForm: FC<CustomerFormProps> = ({ customer, saveCustomer }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CustomerFormData>({
    defaultValues: buildFormDefaultValues(customer),
  });
  const navigate = useNavigate();

  const onSubmit = async (customerFormData: CustomerFormData) => {
    try {
      const customerSaveRequest: CustomerSaveRequest = {
        active: customerFormData.active,
        companyName: customerFormData.companyName,
        externalCompanyReference: nullIfEmpty(customerFormData.externalCompanyReference),
        subscriptionRemovedAfterDoneInDays: customerFormData.subscriptionRemovedAfterDoneInDays,
        trackingConsumer:
          customerFormData.trackingConsumer === "NONE" ? null : TrackingConsumer[customerFormData.trackingConsumer],
        subscriptionInactiveAfterContainerReturnedInDays:
          customerFormData.subscriptionInactiveAfterContainerReturnedInDays,
      };
      await saveCustomer(customerSaveRequest);
      navigate("/customers");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Row>
      <Col xs={12} sm={10} md={8} xl={6} className="m-auto mt-5">
        <div className="bg-white shadow p-3">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={4}>
                Name
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  type="text"
                  placeholder="Name"
                  {...register("companyName", { required: "This field is required." })}
                  isInvalid={errors.companyName?.message !== undefined}
                />
                <Form.Control.Feedback type="invalid" data-testid="validationFeedback">
                  {errors.companyName?.message}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={4}>
                Reference
              </Form.Label>
              <Col sm={8}>
                <Form.Control type="text" placeholder="Reference" {...register("externalCompanyReference")} />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={4}>
                Active
              </Form.Label>
              <Col sm={8} className="d-flex align-items-center">
                <Form.Switch {...register("active")} />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={4}>
                Subscription Inactive Days
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  type="number"
                  placeholder="Days"
                  {...register("subscriptionInactiveAfterContainerReturnedInDays")}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={4}>
                Subscription Removed Days
              </Form.Label>
              <Col sm={8}>
                <Form.Control type="number" placeholder="Days" {...register("subscriptionRemovedAfterDoneInDays")} />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={4}>
                Tracking Consumer
              </Form.Label>
              <Col sm={8}>
                <Form.Select {...register("trackingConsumer")}>
                  <option value="NONE">None</option>
                  <option value={TrackingConsumer.SETLOG.toString()}>Setlog</option>
                </Form.Select>
              </Col>
            </Form.Group>

            <div className="d-flex justify-content-between">
              <Link to="/customers" className="btn btn-outline-primary">
                Cancel
              </Link>
              <Button variant="primary" type="submit">
                Save
              </Button>
            </div>
          </Form>
        </div>
      </Col>
    </Row>
  );
};
